import * as React from "react";
import * as turf from "@turf/turf";

import MapSelect from "./mapSelect";
import ModelReview from "./modelReview";

interface State {
  siteModel?: SiteModel;
  price: Price;
  posterPrice: number;
  location: turf.Feature<turf.Point>;
  selection?: turf.Feature<turf.Polygon>
}

const POSTER_MINIMUM_PRICE = 10;
const POSTER_PER_SQRT_IN = 0.05;
const PRICE_PER_SQRT_IN = 0.25;
const PRICE_PER_BUILDING = 3;
const PRICE_PER_STL = 3;
const MINIMUM_PRICE = 100;
const QUOTABLE_MAXIUM = 2500;
const PRICE_PER_ROOF = 1;
const PRICE_PER_COLOR = 10;

class App extends React.Component<{}, State> {

  constructor(props: {}) {
    super(props)
    this.state = {
      price: MINIMUM_PRICE,
      posterPrice: POSTER_MINIMUM_PRICE,
      location: turf.point([-122.43213, 37.77616]),
      selection: undefined,
    }
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const lat = url.searchParams.get("lat");
    const lon = url.searchParams.get("lon");

    if(!(lat && lon)) {
      fetch(
        "https://5x9246awzi.execute-api.us-east-1.amazonaws.com/Prod/location"
      ).then((response) => {
        return response.json();
      })
      .then((myJson) => {
        const json = JSON.parse(myJson)
        if(json && json["latitude"] && json["longitude"]) {
          const location = turf.point([json["longitude"], json["latitude"]]);
          this.setState({location});
        }
      })
      .catch((e) => {
        console.error("catch", e)
        return;
      });
      return;
    }
    try {
      const location = turf.point([parseFloat(lon), parseFloat(lat)]);
      const selection = this.getDefaultSelction(location)
      this.setState({location, selection});
    } catch (e) {
      console.error(e);
    }
    return;
  }

  modelSize(millimeters: number, scale: Scale): number {
    const meters = millimeters * 1000;
    const inches = meters * 39.37008;
    const size = inches / scale;
    return Number((size).toFixed(1));
  }

  getPrice(
    surfaceArea: number,
    buildings: number,
    customRoofs: number,
    material: Material,
    colors: number,
    custom: boolean,
  ): Price {
    if (custom) {
      return "manual";
    }
    switch(material) {
      case "PLA":
          const price = (surfaceArea * PRICE_PER_SQRT_IN) +
                        (PRICE_PER_BUILDING * buildings) +
                        (customRoofs * PRICE_PER_ROOF) +
                        (colors * PRICE_PER_COLOR);
          if (price < MINIMUM_PRICE) {
            return MINIMUM_PRICE + 
                   (customRoofs * PRICE_PER_ROOF) +
                   (colors * PRICE_PER_COLOR);
          }
          if (price > QUOTABLE_MAXIUM) {
            return "manual";
          }
          return Math.round(price/10)*10;
      case "STL":
          return (PRICE_PER_STL * buildings) + (customRoofs * PRICE_PER_ROOF);
    }
  }

  getPosterPrice(width: number, height: number): number {
    const posterArea =  width * height;
    const posterPrice = posterArea * POSTER_PER_SQRT_IN;
    if (posterPrice <= POSTER_MINIMUM_PRICE) {
      return POSTER_MINIMUM_PRICE;
    }
    return Math.round(posterPrice/5)*5;
  }

  setSiteModel(siteModel: SiteModel | undefined) {
    if (siteModel === undefined) {
      this.setState({
        siteModel: undefined,
        price: MINIMUM_PRICE,
        posterPrice: POSTER_MINIMUM_PRICE
      });
      return;
    }
    let aprxTotalSurfaceArea = 0;
    let customRoofs = 0;
    let colors = new Set();
    let custom = false;
    for (const building of Object.values(siteModel.buildings)) {
      const h = building.height * 1000 * 39.37008 / siteModel.scale,
            w = building.width * 1000 * 39.37008 / siteModel.scale,
            d = building.depth * 39.37008 / siteModel.scale;
      aprxTotalSurfaceArea += 2 * (h*w + w*d + h*d);

      if (building.roofShape !== 'flat') {
        customRoofs += 1;
      }
      if (!['flat', 'hipped'].includes(building.roofShape)) {
        custom = true;
      }
      if (building.custom) {
        custom = true;
      }
      colors.add(building.color)
    }
    const price = this.getPrice(
      aprxTotalSurfaceArea,
      Object.values(siteModel.buildings).length,
      customRoofs,
      siteModel.material,
      colors.size,
      custom
    );

    const posterPrice =this.getPosterPrice(
      this.modelSize(siteModel.width, siteModel.scale),
      this.modelSize(siteModel.height, siteModel.scale)
    )

    this.setState({siteModel, price, posterPrice});
  }

  getDefaultSelction(center: turf.Feature<turf.Point>): turf.Feature<turf.Polygon> | undefined {
    const halfSide = 0.12;
    const top = turf.transformTranslate(center, halfSide, 0);
    const right = turf.transformTranslate(center, halfSide, 90);
    const bottom = turf.transformTranslate(center, halfSide, 180);
    const left = turf.transformTranslate(center, halfSide, 270);

    var features = turf.featureCollection([top, right, bottom, left]);
    return turf.envelope(features);
  }

  render() {
    return (
      <>
        <div style={{display: this.state.siteModel ? "none": "inherit"}}>
          <MapSelect
            setSiteModel={this.setSiteModel.bind(this)}
            siteModel={this.state.siteModel}
            location={this.state.location}
            selection={this.state.selection}
          />
        </div>
        <div style={{display: this.state.siteModel ? "inherit": "none"}}>
          <ModelReview
            setSiteModel={this.setSiteModel.bind(this)}
            siteModel={this.state.siteModel}
            price={this.state.price}
            posterPrice={this.state.posterPrice}
          />
        </div>

      </>
    )

  }
}

export default App
