import * as React from "react"

interface Props {
  onChange: (event: React.ChangeEvent<HTMLDivElement>) => void;
  value: string;
  options: {
    value: string;
    label: string;
  }[]
}

const Radio = ({onChange, value, options}: Props) => {
  return (
    <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={onChange}>
      {options.map((option) => (
        <label
          className={`btn btn-secondary ${value === option.value ? 'active':''}`}
          key={option.value}
          style={{zIndex: "inherit"}}
        >
          <input
            type="radio"
            name="options"
            value={option.value}
            checked={value === option.value}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
}

export default Radio



