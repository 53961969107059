import * as React from "react"

interface Props {
  price: Price;
  posterPrice: number;
  base: Base;
}

interface State {}

class PriceDisplay extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  getPrice(price: number): number {
    switch(this.props.base) {
      case "none":
          return price;
      case "poster":
          return price + this.props.posterPrice;
    }
  }

  render() {
    if (this.props.price === "manual") {
      return (
        <div>
          Manual Quote Required
        </div>
      )
    }
    return (
      <div>
        Price estimate: ${this.getPrice(this.props.price)}
      </div>
    );
  }
}

export default PriceDisplay
