import * as React from "react"

interface Props {
  viewportId: string;
}

interface State {}

class WebGlViewport extends React.Component<Props, State> {

  onMouseLeave() {
    document.body.style.overflow = "visible";
    document.body.style.marginRight = "0px";
  }
  
  onMouseEnter() {
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = "15px";
  }

  render() {
    return (
      <div
        id={this.props.viewportId}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{width: "100%", height: "100%"}}
      />
    );
  }
}

export default WebGlViewport;
