import * as React from "react"
import Creatable from 'react-select/creatable';

interface Props {
  onChange: (event: {value: string; label: string}) => void;
  value: RoofShape;
  label: string;
  roofError?: boolean;
}


const createLabel = (value: string) => `Request custom ${value} roof`

const options = [
  {value: "flat", label: "Flat"},
  {value: "hipped", label: "Hipped"}
]


const RoofSelect = ({onChange, value, label, roofError}: Props) => {
  const selectedOption = options.find((o) => o.value === value)
  const onCreateOption = (value: string) => {
    const newOption = {value, label: value}
    options.push(newOption)
    onChange(newOption)
  }
  return (
    <div className="form-group">
      <label htmlFor="roofShapeSelect">{label}</label>
      <Creatable
        onChange={onChange}
        value={selectedOption}
        options={[
          {value: "flat", label: "Flat"},
          {value: "hipped", label: "Hipped"}
        ]}
        isMulti={false}
        formatCreateLabel={createLabel}
        isLoading={false}
        onCreateOption={onCreateOption}
      />
      {roofError ? <small>Unable to generate preview</small> : null}
      {selectedOption ? null : <small>Preview unavailable</small>}
    </div>
  );
}

export default RoofSelect
