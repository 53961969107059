import { Node } from "./point"

class Edge {
  v1: Node;
  v2: Node;
  label: string;
  splitVersion: number;

  constructor(v1: Node, v2: Node) {
    this.v1 = v1;
    this.v2 = v2;
    this.label = v1.label + '-' + v2.label;
    this.splitVersion = 0;
    // this.intersection = intersection(v1, v2);
  }
}

export default Edge