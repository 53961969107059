import * as React from "react"

const Alert = (props: {alert?: Alert}) => {
    if (!props.alert) {
        return null;
      }
      return (
        <div
          className={`alert alert-${props.alert.level}`}
          role="alert"
          style={{margin: "10px 0"}}
        >
          {props.alert.message}
        </div>
      );
}

export default Alert
