import * as React from "react"
import Alert from "../ui/alert";
import ColorPicker from "../ui/colorPicker";
import RoofSelect from "../ui/roofSelect";

interface Props {
  setSiteModel: (models: SiteModel) => void;
  setSelectedModel: (selectedId: string) => void;
  siteModel?: SiteModel;
  selectedId?: string;
  controls?: Controls;
}

interface State {
  alert?: Alert;
}

class BuildingEditor extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  onRoofChange(value: {value: string; label: string}) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for roof shape change")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    siteModel.buildings[id].roofShape = value.value as RoofShape;
    this.props.setSiteModel(siteModel);
    controls.updateBuilding(siteModel.buildings[id], id);
  }

  onCustomModelChange(e: React.ChangeEvent<HTMLInputElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }
    siteModel.buildings[id].custom = e.target.checked;
    this.props.setSiteModel(siteModel);
  }

  removeSelected(_e: React.MouseEvent<HTMLButtonElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }

    if(Object.values(siteModel.buildings).length <= 1) {
      this.setState({alert: {
        level: "warning",
        message: "Can not remove the last model from the selection",
      }});
      return;
    }

    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }

    // remove model
    controls.removeModel(id);
    delete siteModel.buildings[id];
    this.props.setSiteModel(siteModel);

    // make new selection
    const selectedId = Object.values(siteModel.buildings)[0].id;
    this.props.setSelectedModel(selectedId)
  }

  onHieghtChangeFt(e: React.ChangeEvent<HTMLInputElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }
    const inputIN = document.getElementById("heightIN")
    if(!inputIN) {
      console.error("input with heightIN not found")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }

    const inches = parseInt((inputIN as HTMLInputElement).value) + (parseInt(e.target.value) * 12);
    if (inches > 1) {
      const meters = inches / 39.370;
      siteModel.buildings[id].depth = meters;
      this.props.setSiteModel(siteModel);
      controls.updateBuilding(siteModel.buildings[id], id);
    }
  }

  onHieghtChangeIn(e: React.ChangeEvent<HTMLInputElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }
    const inputFT = document.getElementById("heightFT")
    if(!inputFT) {
      console.error("input with heightFT not found")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    const inches = (parseInt((inputFT as HTMLInputElement).value) * 12) + parseInt(e.target.value);
    if (inches > 1) {
      const meters = inches/ 39.370;
      siteModel.buildings[id].depth = meters;
      this.props.setSiteModel(siteModel);
      controls.updateBuilding(siteModel.buildings[id], id);
    }
  }

  onNotesChange(e: React.ChangeEvent<HTMLInputElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }
    siteModel.buildings[id].customNotes = e.target.value;
    this.props.setSiteModel(siteModel);
  }

  onColorChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for remove model")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    siteModel.buildings[id].color = parseInt(e.target.value) as Color;
    this.props.setSiteModel(siteModel);
    controls.updateBuilding(siteModel.buildings[id], id);
  }

  renderCustomNotes(): JSX.Element | null {
    const siteModel = this.props.siteModel
    const selectedId = this.props.selectedId;
    if (!(selectedId && siteModel)) {
      return null;
    }
    const selected = siteModel.buildings[selectedId];
    if (!selected.custom) {
      return null
    }
    return (
      <div className="form-group">
        <textarea
          className="form-control"
          rows={3}
          name="notes"
          placeholder="Describe custom features"
          onChange={this.onNotesChange.bind(this)}
        ></textarea>
    </div>
    );
  }

  render() {
    const siteModel = this.props.siteModel
    const selectedId = this.props.selectedId;
    if (!(selectedId && siteModel)) {
      return null;
    }
    const selected = siteModel.buildings[selectedId];
    if (!selected) {
      return (<p>Editor Unavailable. Double click on any building to edit</p>)
    }
    const inches = Math.round(selected.depth * 39.370);
    return (
      <>
        <Alert alert={this.state.alert}/>
        <RoofSelect
          label="Roof Shape"
          value={selected.roofShape}
          onChange={this.onRoofChange.bind(this)}
          roofError={selected.roofError}
        />
        <ColorPicker
          label="Color"
          value={selected.color}
          onChange={this.onColorChange.bind(this)}
        />
        <fieldset className="form-group">
          <label>Height</label>
          <div className="row">
            <div className="col" style={{paddingRight: "3px"}}>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  max="3000"
                  id="heightFT"
                  onChange={this.onHieghtChangeFt.bind(this)}
                  value={Math.floor(inches/12)}/>
                <div className="input-group-append">
                  <span className="input-group-text">ft</span>
                </div>
              </div>
            </div>
            <div className="col" style={{paddingLeft: "3px"}}>
              <div className="input-group">
                <input 
                  type="number"
                  className="form-control"
                  min="0"
                  max="12"
                  id="heightIN"
                  onChange={this.onHieghtChangeIn.bind(this)}
                  value={inches % 12}
                />
                <div className="input-group-append">
                  <span className="input-group-text">in</span>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={selected.custom}
              id="customModeling"
              onChange={this.onCustomModelChange.bind(this)}
            />
            <label className="form-check-label" htmlFor="customModeling">
              Request custom modeling
            </label>
          </div>
          {this.renderCustomNotes()}
        </div>
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={this.removeSelected.bind(this)}
        >
          Remove From Order
        </button>
      </>
    );
  }
}

export default BuildingEditor
