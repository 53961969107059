import * as uuidv4 from 'uuid/v4';
import * as turf from "@turf/turf";
import * as paper from "paper";

interface BuildingData {
  id: string;
  bbox: turf.BBox;
  height: number | null;
  coordinates: {
    exterior: number[][],
    interiors: number[][][],
  };
}

interface BuildingDataWrapper {
  // HACK: totalHeight is used to compute the average height of all buildings in the site model
  totalHeight: number;
  data: BuildingData[];
}

interface PaperPath {
  id: string;
  path: paper.Path;
}

const DEFAULT_HEIGHT = 4.6;
const SCALE =  1000;

const pathFormBuilding = (coordinates: number[][], bbox: turf.BBox): string | null => {
  const origin = turf.point([bbox[0], bbox[1]]);

  const path = [];
  for (const coordinate of coordinates) {
    if (
      coordinate[0] < bbox[0] ||
      coordinate[1] < bbox[1] ||
      coordinate[0] > bbox[2] ||
      coordinate[1] > bbox[3]
    ) {
      // TODO: change this to outside of user selection with paper.js
      // if coordinates are outside bounding box exclude the model
      return null;
    }
    const x = turf.distance(
      origin,
      turf.point([coordinate[0], bbox[1]])
    );
    const y = turf.distance(
      origin,
      turf.point([bbox[0], coordinate[1]])
    );

    path.push(x * SCALE);
    path.push(y * SCALE);
  }
  return `M${path.join(',')}Z`;
}

const getHeight = (feature: mapboxgl.MapboxGeoJSONFeature): number | null => {
  if(!feature.properties) {
    console.error("missing propertires for feature for", JSON.stringify(feature.geometry));
    return DEFAULT_HEIGHT;
  }
  // 3 seems to be the map box default when it has no data
  if (feature.properties.height && feature.properties.height !== 3) {
    return parseFloat(feature.properties.height);
  }
  return null;
}

const updateBounds = (bbox: turf.BBox, lat: number, lon: number): turf.BBox => {
  if (lon < bbox[0]) {
    bbox[0] = lon
  }
  if (lat < bbox[1]) {
    bbox[1] = lat
  }
  if (lon > bbox[2]) {
    bbox[2] = lon
  }
  if (lat > bbox[3]) {
    bbox[3] = lat
  }
  return bbox;
}

const createBuildingData = (features: mapboxgl.MapboxGeoJSONFeature[]): BuildingDataWrapper => {
  const data: BuildingData[] = [];
  let totalHeight = 0;
  for (const feature of features) {
    if(feature.geometry.type !== "Polygon") {
      console.error(`skiped feature type:${feature.geometry.type}`)
      continue;
    }
    const bbox = turf.bbox(feature as any as turf.Geometry),
          coordinates = {
            exterior: feature.geometry.coordinates[0],
            interiors: feature.geometry.coordinates.splice(1)
          },
          height = getHeight(feature),
          id = uuidv4();
          data.push({id, height, coordinates, bbox});
    if (height) {
      totalHeight = totalHeight + height;
    } else {
      totalHeight = totalHeight + DEFAULT_HEIGHT;
    }
    
  }
  return {totalHeight, data};
}

const createSiteModel = (
  buildings: BuildingDataWrapper,
  bbox: turf.BBox,
  map: string,
  mapScaleX: number,
  mapScaleY: number,
  mapCenterX: number,
  mapCenterY: number,
): SiteModel => {
  const siteModel = {
    buildings: {},
  } as SiteModel;
  const avgHeight = buildings.totalHeight/buildings.data.length;

  for (let building of buildings.data) {
    const width = turf.distance(
      turf.point([building.bbox[0], building.bbox[1]]),
      turf.point([building.bbox[2], building.bbox[1]])
    );
    const height = turf.distance(
      turf.point([building.bbox[0], building.bbox[1]]),
      turf.point([building.bbox[0], building.bbox[3]])
    );

    // TODO: can we pull geojson building roof data?
    const path = pathFormBuilding(building.coordinates.exterior, bbox)
    if (path === null) {
      continue;
    }
    const holes: string[] = [];
    building.coordinates.interiors.forEach(interior => {
      const hole = pathFormBuilding(interior, bbox)
      if (hole === null) {
        console.error("Hole in building is outside of the bounding box")
        return;
      }
      holes.push(hole)
    });

    siteModel.buildings[building.id] = {
      id: building.id,
      width,
      height,
      depth: building.height || avgHeight,
      roofShape: "flat",
      path,
      holes,
      custom: false,
      customNotes: "",
      color: 0xB2756C,
    };
  }

  // merge buildings that intersect into a single building
  const canvas = document.getElementById('paper');
  if (canvas) {
    paper.setup(canvas as HTMLCanvasElement);
    let paths: PaperPath[] = [];
    for (let building of Object.values(siteModel.buildings)) {
      const path = new paper.Path(building.path);
      // path.fillColor = "green"
      let intersects = false
      let updatedPaths: PaperPath[] = [];
      for(let p of paths) {
        // only merge the models if they are significantly overlapping and the same height
        const intersection: number = (path.intersect(p.path) as any).area;
        if(intersection > 2 && (building.depth === siteModel.buildings[p.id].depth)) {
          const updatedPath = path.unite(p.path);
          siteModel.buildings[p.id].path = updatedPath.pathData;
          siteModel.buildings[p.id].holes.push(...building.holes);
          delete siteModel.buildings[building.id];
          intersects = true;
        }
        updatedPaths.push(p)
      }

      if(intersects) {
        paths = updatedPaths;
      } else {
        paths.push({
          id: building.id,
          path
        });
      }
    }
    // paper.view.draw();
  } else {
    console.error("missing canvas for paper.js")
  }

  siteModel.width = turf.distance(
    turf.point([bbox[0], bbox[1]]),
    turf.point([bbox[2], bbox[1]])
  );
  siteModel.height = turf.distance(
    turf.point([bbox[0], bbox[1]]),
    turf.point([bbox[0], bbox[3]])
  );

  let svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${siteModel.width * SCALE}" height="${siteModel.height * SCALE}"><g fill="#f0f0f0" stroke="#222" stroke-width="2" >`
  for (let building of Object.values(siteModel.buildings)) {
    svg += `<path d="${building.path}"/>`
  }
  svg += '</g></svg>'
  siteModel.svg = svg;
  siteModel.map = map;
  siteModel.mapScaleX = mapScaleX;
  siteModel.mapScaleY = mapScaleY;
  siteModel.mapCenterX = mapCenterX;
  siteModel.mapCenterY = mapCenterY;
  siteModel.scale = 1200;
  siteModel.material = 'PLA';
  siteModel.base = 'poster';
  return siteModel;
}

export default {
  createSiteModel,
  createBuildingData,
  updateBounds
};
