import * as React from "react"
import Alert from "../ui/alert";
import Modal from 'react-modal';

interface Props {
  siteModel: SiteModel;
  controls: Controls;
  price: Price;
  posterPrice: number;
}

interface State {
    showModal: boolean;
    alert?: Alert;
    name: string;
    email: string;
    notes: string;
    sent: boolean;
}

class RequestModel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
        showModal: false,
        alert: undefined,
        sent: false,
        name: "",
        email: "",
        notes: "",
    }
  }

  openModal() {
    this.setState({showModal: true});
  }

  closeModal() {
    this.setState({showModal: false, alert: undefined});
  }

  modelSize(millimeters: number): number {
    if(!this.props.siteModel) {
      console.error("can not calculate site model size when site model does not exist")
      return 0;
    }
    const meters = millimeters * 1000;
    const inches = meters * 39.37008;
    const size = inches / this.props.siteModel.scale;
    return Number((size).toFixed(1));
  }

  sendEmail() {
    if (!this.state.email) {
      this.setState({
        alert: {
          level: "warning",
          message: "Email is required"
        }
      })
      return;
    }
    if (!this.state.name) {
      this.setState({
        alert: {
          level: "warning",
          message: "Name is required"
        }
      })
      return;
    }
    // upload files to s3
    const buildings: string[] = [];
    Object.values(this.props.siteModel.buildings).forEach((building) => {
      const stl = this.props.controls.getStlOfModel(building.id)
      if (!stl) {
        console.error("Unable to generate stl for model", building.id)
        return;
      }
      buildings.push(stl);
    })
    const data = JSON.stringify({
      buildings,
      name: this.state.name,
      email: this.state.email,
      notes: this.state.notes,
      material: this.props.siteModel.material,
      map: `https://api.mapbox.com/styles/v1/timistesting/cjwh11j8h0b7u1cmk0y9cc4vz${this.props.siteModel.map}`,
      base: this.props.siteModel.base,
      modelData: Object.values(this.props.siteModel.buildings),
      scale: this.props.siteModel.scale,
      price: this.props.price,
      posterPrice: this.props.posterPrice,
      svg: this.props.siteModel.svg,
      modelSize: `${this.modelSize(this.props.siteModel.width)}" x ${this.modelSize(this.props.siteModel.height)}"`,
      posterSize: `${this.modelSize(this.props.siteModel.width)/this.props.siteModel.mapScaleX}" x ${this.modelSize(this.props.siteModel.height)/this.props.siteModel.mapScaleY}"`,
    })

    // send email
    this.setState({sent: true,});
    fetch(
      "https://5x9246awzi.execute-api.us-east-1.amazonaws.com/Prod/send",
      {
        method: "POST",
        body: data
      }
    ).then((response) => {
      if(response.status < 300 && response.status > 199) {
        this.setState({
          alert: {
            level: "success",
            message: "Thank you for your submission. We have recived your message and someone will reach out to you shortly"
          },
          sent: true,
        });
        return;
      }
      this.setState({
        alert: {
          level: "danger",
          message: (
            <>
              Something went wrong while trying to request a quote. Please contact us at
              <a href="mailto:contact@titanic.design"> contact@titanic.design</a>
            </>
          )
        },
        sent: false,
      });
    }).catch(() => {
      this.setState({
        alert: {
          level: "danger",
          message: (
            <>
              Something went wrong while trying to request a quote Please contact us at
              <a href="mailto:contact@titanic.design"> contact@titanic.design</a>
            </>
          )
        },
        sent: false,
      });
    });
  }

  onEmailChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({email: event.target.value});
  }
  onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({name: event.target.value});
  }
  onNotesChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({notes: event.target.value});
  }

  render() {
    return (
      <>
        <button
          type="button"
          onClick={this.openModal.bind(this)}
          className="btn btn-primary"
          disabled={this.state.sent}
        >
          Request Model
        </button>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal.bind(this)}
          contentLabel="Request Model"
        >
          <h4 className="h3" style={{marginBottom: "40px"}}>
            Request Model
          </h4>
          {this.state.sent ? (
<img height="1" width="1" style={{display:"none"}} alt="" src="https://dc.ads.linkedin.com/collect/?pid=580116&conversionId=1050908&fmt=gif" />
) : null}
          <form id="form" action="#">
            <Alert alert={this.state.alert}/>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  onChange={this.onNameChange.bind(this)}
                  value={this.state.name}
                  id="name"
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                  onChange={this.onEmailChange.bind(this)}
                  id="email"
                  value={this.state.email}
                />
            </div>
            <div className="form-group">
                <label htmlFor="scale">Notes</label>
                <textarea
                  className="form-control"
                  rows={3}
                  id="scale"
                  onChange={this.onNotesChange.bind(this)}
                  value={this.state.notes}
                ></textarea>
            </div>
            <button
              type="button"
              onClick={this.sendEmail.bind(this)}
              className="btn btn-primary"
              disabled={this.state.sent}
            >
              Request Model
            </button>
          </form>
        </Modal>
      </>
    );
  }
}

export default RequestModel
