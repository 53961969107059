import * as React from "react";
import Alert from "../ui/alert";
import Loading from "../ui/loading";
import geoJSONParser from '../../utils/geoJSONParser';
import * as mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as geoViewport from '@mapbox/geo-viewport';

import styled from 'styled-components';

const MAPBOX_TOKEN = 'pk.eyJ1IjoidGltaXN0ZXN0aW5nIiwiYSI6ImNqeDN2eTdhNTAxb2M0MHBhZTZkOXlhNGQifQ.lu_oTQ5bjVZFahbgHCA0Dw'

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 70px;
  height: calc(100vh - 70px);
`;

const Bottom = styled.div`
  display: Grid;
  grid-template-columns: auto 300px;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;


interface Props {
  setSiteModel: (siteModel: SiteModel | undefined) => void;
  siteModel?: SiteModel;
  location: turf.Feature<turf.Point>;
  selection?: turf.Feature<turf.Polygon>
}

interface State {
  alert?: Alert;
  generating: boolean;
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class MapSelect extends React.Component<Props, State> {
  mapContainer = 'map';
  map: mapboxgl.Map;
  draw;
  // Area in square meters
  maxArea = 500000;

  constructor(props: Props) {
    super(props);
    this.state = {
      alert: undefined,
      generating: false,
    }
  }

  componentDidMount() {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [
        (this.props.location.geometry as any).coordinates[0],
        (this.props.location.geometry as any).coordinates[1]
      ],
      zoom: 17,
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: 'draw_polygon',
      controls: {
        polygon: true
      },
    });
    var geocoder = new MapboxGeocoder({ accessToken: mapboxgl.accessToken });
 
    this.map.addControl(geocoder);
    this.map.addControl(new mapboxgl.GeolocateControl({
      trackUserLocation: true
    }));
    this.map.addControl(this.draw);
    this.map.on('draw.create', (e) => {
      this.draw.getAll().features.forEach(feature => {
        if(feature.id !== e.features[0].id) {
          this.draw.delete(feature.id)
        }
      });
    });

    this.map.on('load', () => {
      if(this.props.selection) {
        const featureId = this.draw.add(this.props.selection)[0];
        this.draw.changeMode('direct_select', {featureId});
        this.generateSiteModel();
        }
    });
  }

  componentDidUpdate(prevProps: Props) {
    if(JSON.stringify(this.props.location) !== JSON.stringify(prevProps.location)) {
      // HACK: this code is conditional on timing
      // in order to render the page for static viewing a default center is set
      // this center may be updated with a lat and lon query param
      // in that case we need to update the map center and selection before the site model is generated
      this.map.setCenter([
        (this.props.location.geometry as any).coordinates[0],
        (this.props.location.geometry as any).coordinates[1]
      ])
      if(this.props.selection) {
        this.draw.deleteAll();
        this.draw.changeMode('draw_polygon')
        const featureId = this.draw.add(this.props.selection)[0];
        this.draw.changeMode('direct_select', {featureId});
      }
    }
  }

  generateSiteModel() {
    const bbox = turf.bbox(this.draw.getAll())
    const sw = new mapboxgl.LngLat(bbox[0], bbox[1]);
    const ne = new mapboxgl.LngLat(bbox[2], bbox[3]);
    let [staticMapWidth, staticMapHeight] = [1024, 1024]
    const viewport = geoViewport.viewport(bbox, [staticMapWidth, staticMapHeight], 16, 20, 512);
    const centerLat = viewport.center[1].toFixed(4),
          centerLon = viewport.center[0].toFixed(4),
          zoom = viewport.zoom,
          rotation = 0,
          pitch = 0;
    const staticMap = `/static/${centerLon},${centerLat},${zoom},${rotation},${pitch}/${staticMapWidth}x${staticMapHeight}?access_token=${MAPBOX_TOKEN}&attribution=false&logo=false`

    // set ui map to viewport.zoom
    this.map = this.map.setZoom(zoom);

    // set ui map to viewport.center
    this.map = this.map.setCenter([centerLon,centerLat]);
    // calculate new bbox pixel size
    const bboxPixelWidth = (
      this.map.project(new mapboxgl.LngLat(bbox[2], bbox[3])).x -
      this.map.project(new mapboxgl.LngLat(bbox[0], bbox[3])).x
    );
    const bboxPixelHeight = (
      this.map.project(new mapboxgl.LngLat(bbox[0], bbox[1])).y -
      this.map.project(new mapboxgl.LngLat(bbox[0], bbox[3])).y
    );
    // calculate new center pixel coordintates
    const pixelCenter = this.map.project(new mapboxgl.LngLat(centerLon, centerLat))

    this.setState({generating: true})
    sleep(1000).then(() => {
      const features = this.map.queryRenderedFeatures(
        [this.map.project(sw), this.map.project(ne)],
        {layers: ["building"]}
      )
      const buildingData = geoJSONParser.createBuildingData(features);
      const siteModel = geoJSONParser.createSiteModel(
        buildingData,
        bbox,
        staticMap,
        (bboxPixelWidth/staticMapWidth),
        (bboxPixelHeight/staticMapHeight),
        (pixelCenter.x - this.map.project(sw).x)/bboxPixelWidth,
        (pixelCenter.y - this.map.project(ne).y)/bboxPixelHeight
      );

      const buildings = Object.values(siteModel.buildings)
      if(buildings.length < 1) {
        this.setState({
          alert: {
            level: "warning",
            message: (
              <>
                We were unable to find building data for the area you selected.
                Please update the selection or <a href="https://titanic.design/contact">contact us</a> and our designers will model the area.
              </>
            ),
          },
          generating: false
        });
        return;
      }
      this.props.setSiteModel(siteModel);
      this.setState({alert: undefined, generating: false});
    });

  }

  generateBuildings() {
    const selection = this.draw.getAll();
    if (
      !(
        selection &&
        selection.features &&
        selection.features[0] &&
        selection.features[0].geometry.coordinates[0].length > 2
      )
    ) {
      this.setState({
        alert: {
          level: "warning",
          message: "Unable to generate models. Please select an area on the map above."
        }
      });
      return;
    }

    const bbox = turf.bbox(this.draw.getAll())
    if (bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
      this.setState({
        alert: {
          level: "warning",
          message: "Unable to generate models. Please select an area on the map above."
        }
      });
      return;
    }

    const area = turf.area(this.draw.getAll());
    if (area > this.maxArea) {
      this.setState({
        alert: {
          level: "warning",
          message: (
            <>
              Selected area is to large. Please select a smaller area or contact us at
              <a href="mailto:contact@titanic.design"> contact@titanic.design</a>
            </>
          )
        }
      });
      return;
    }
    this.generateSiteModel()
  }

  clearSelection() {
    this.draw.deleteAll();
    this.draw.changeMode('draw_polygon')
    this.props.setSiteModel(undefined);
    this.setState({alert: undefined});
  }

  loading() {
    if (!this.state.generating) {
      return null;
    }
    return (
      <div style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(188, 195, 198, 0.65)",
        zIndex: 10000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}>
        <div>
          <Loading/>
          <h4>Generating Models</h4>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Grid>
        <div className="container-fluid" style={{padding: 0}}>
          <div id={this.mapContainer} style={{position: "relative", height: "100%", width: "100%"}}>
            {this.loading()}
          </div>
        </div>
        <div className="container-fluid">
          <Bottom>
            <div>
              <Alert alert={this.state.alert}/>
            </div>
            <ButtonContainer>
              <button
                type="button"
                className="btn btn-secondary"
                style={{marginRight: "10px"}}
                onClick={this.clearSelection.bind(this)}
                disabled={this.state.generating}
              >
                Clear Selection
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.generateBuildings.bind(this)}
                disabled={this.state.generating}
              >
                {this.props.siteModel ? "Update Models" : "Generate Models"}
              </button>
              <canvas id="paper" style={{display: "none"}}></canvas>
            </ButtonContainer>
          </Bottom>
        </div>
      </Grid>
    );
  }
}

export default MapSelect
