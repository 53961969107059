import * as React from "react"
import Alert from "../ui/alert";
import styled from 'styled-components';

import renderBuilding from "../../utils/modelPreview/renderBuilding"
import RequestModel from "./requestModel";
import WebGlViewport from "./webGlViewport";
import BuildingEditor from "./buildingEditor";
import ModelEditor from "./modelEditor";
import PriceDisplay from "./priceDisplay";

const Grid = styled.div`
  display: Grid;
  grid-template-columns: 310px auto;
  grid-template-rows: auto 70px;
  grid-template-areas: 
    "sidebar main"
    "footer footer";
  height: calc(100vh - 70px);
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const Form = styled.div`
  backgroundColor: #F7F8FA;
  overflow: auto;
  height: calc(100vh - 140px);
`;

interface Props {
  setSiteModel: (siteModel?: SiteModel) => void;
  siteModel?: SiteModel;
  price: Price;
  posterPrice: number;
}

interface State {
  alert?: Alert;
  selectedId?: string;
  controls?: Controls;
}

class ModelReview extends React.Component<Props, State> {
  viewportId = "WebGL-output";

  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  setSelectedModel(id: string) {
    if (id === this.state.selectedId) {
      return;
    }
    const siteModel = this.props.siteModel;
    if (!siteModel) {
      console.error("no models. But one was just selected")
      return;
    }

    const controls = this.state.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    if(!siteModel.buildings[id]) {
      // the object selected is not a model
      return;
    }
    const previousSelected = this.state.selectedId ? siteModel.buildings[this.state.selectedId] : undefined;
    controls.updateSelected(siteModel.buildings[id], previousSelected);

    this.setState({
      selectedId: id,
    });
  }

  resetSelection() {
    this.props.setSiteModel(undefined);
  }

  onRoofError() {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.state.selectedId;
    if(!(id && siteModel)) {
      console.error("models or selecetion not found for roof error")
      return;
    }

    siteModel.buildings[id].roofError = true;
    this.props.setSiteModel(siteModel);
  }

  componentDidUpdate(prevProps: Props, _prevState: State): void {
    if (!this.props.siteModel) {
      return;
    }
    if (JSON.stringify(prevProps.siteModel) === JSON.stringify(this.props.siteModel)) {
      return;
    }

    const siteModel = this.props.siteModel;
    const selectedId = Object.values(siteModel.buildings)[0].id;
    const controls = renderBuilding(
      siteModel,
      selectedId,
      this.viewportId,
      this.setSelectedModel.bind(this),
      this.onRoofError.bind(this)
    )
    this.setState({ selectedId, controls })
  }

  renderRequestModel() {
    const siteModel = this.props.siteModel
    const controls = this.state.controls;
    if (siteModel && controls) {
      return (
        <RequestModel
          siteModel={siteModel}
          controls={controls}
          price={this.props.price}
          posterPrice={this.props.posterPrice}
        />
      );
    }
    return null;
  }

  render() {
    /* use block/none styling to ensure view port is always in the DOM */
    return (
      <div id="test" style={{display: this.props.siteModel? "block": "none"}}>
        <Alert alert={this.state.alert}/>
        <Grid>
          <Form>
            <div className="card" style={{borderRadius: 0}}>
              <div className="card-header">
                Edit Site Model
              </div>
              <div className="card-body">
                <ModelEditor
                  setSiteModel={this.props.setSiteModel.bind(this)}
                  siteModel={this.props.siteModel}
                  controls={this.state.controls}
                  posterPrice={this.props.posterPrice}
                  selectedId={this.state.selectedId}
                />
              </div>
            </div>
            <div className="card" style={{borderRadius: 0, marginTop: "-1px"}}>
              <div className="card-header">
                Edit Selected Building<br/>
                <small>Double Click on building to edit</small>
              </div>
              <div className="card-body">
                <BuildingEditor
                  setSiteModel={this.props.setSiteModel.bind(this)}
                  setSelectedModel={this.setSelectedModel.bind(this)}
                  siteModel={this.props.siteModel}
                  controls={this.state.controls}
                  selectedId={this.state.selectedId}
                />
              </div>
            </div>
          </Form>
          <div style={{backgroundColor: "#f2f3f4"}}>
            <WebGlViewport
              viewportId={this.viewportId}
            />
          </div>
          <div className="container-fluid" style={{gridArea: "footer"}}>
            <Bottom>
              <button
                type="button"
                className="btn btn-secondary"
                style={{marginRight: "10px"}}
                onClick={this.resetSelection.bind(this)}
              >
                Reset Selection
              </button>
              <Bottom>
                <PriceDisplay
                  price={this.props.price}
                  posterPrice={this.props.posterPrice}
                  base={this.props.siteModel ? this.props.siteModel.base : "none"}
                />
                <span className="ml-3">
                  {this.renderRequestModel.bind(this)()}
                </span>
              </Bottom>
            </Bottom>
          </div>
        </Grid>
      </div>
    );
  }
}

export default ModelReview
