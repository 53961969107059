import * as THREE from 'three';
import transformSVGPathExposed from './d3-threeD';
import pathToPoints from './pathToPoints';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as exportSTL from '@doodle3d/threejs-export-stl';
import Point from "./skeleton/point";
import createRoof from "./roofs/hipped";

const selectedColor = 0x3398CA,
      scale = 1000,
      baseName = 'base';

function getGeometry(
  path: string,
  holes: string[],
  depth: number,
  roofShape: RoofShape,
  onRoofError: () => void,
): THREE.BufferGeometry {
  const exterior = transformSVGPathExposed(path)[0];
  if (roofShape === "hipped" && holes.length === 0) {
    const footprint = pathToPoints(exterior)
    const roofAngle = 45
    return createRoof(footprint, roofAngle, depth, onRoofError)
  } else {
    const shapes = [];
    for(let hole of holes) {
      shapes.push(transformSVGPathExposed(hole)[0])
    }
    exterior.holes = shapes;
  
    return new THREE.ExtrudeBufferGeometry(
      exterior,
      {
        depth,
        bevelEnabled: false
      }
    );
  }
}

function createShape(
  path: string,
  holes: string[],
  depth: number,
  selected: boolean,
  name: string,
  color: number,
  roofShape: RoofShape,
  onRoofError: () => void
): THREE.Mesh {

  const geometry = getGeometry(path, holes, depth, roofShape, onRoofError)

  // assign two materials
  var meshMaterial = new THREE.MeshPhongMaterial({
    color: selected ? selectedColor : color,
    shininess: 0,
    side: THREE.DoubleSide
  });
  var mesh = new THREE.Mesh(geometry, meshMaterial);
  mesh.rotation.x = - Math.PI / 2;

  mesh.castShadow = true;
  mesh.name = name;
  return mesh;
}

function directionalLight(position: THREE.Vector3): THREE.DirectionalLight {
  const light = new THREE.DirectionalLight(0xffffff, 0.3);
  light.position.set(position.x, position.y, 45);
  light.position.multiplyScalar( 1.3 );
  light.castShadow = true;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  const d = 300;
  light.shadow.camera.left = - d;
  light.shadow.camera.right = d;
  light.shadow.camera.top = d;
  light.shadow.camera.bottom = - d;
  light.shadow.camera.far = 1000;
  return light
}

function addGround(scene: THREE.Scene, siteModel: SiteModel): void {
  if (siteModel.base === 'poster') {
    new THREE.TextureLoader().load(`https://api.mapbox.com/styles/v1/timistesting/cjvisueew0m7g1cqke7cf1vcn${siteModel.map}`, function(texture) {
      texture.center.set(1-siteModel.mapCenterX, siteModel.mapCenterY)
      texture.repeat.set(siteModel.mapScaleX, siteModel.mapScaleY)
      const material = new THREE.MeshLambertMaterial( { map: texture } );
      material.flatShading = true
      const mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(
          (siteModel.width * scale),
          (siteModel.height * scale)
        ),
        material
      );
      mesh.rotation.x = - Math.PI / 2;
      mesh.receiveShadow = true;

      mesh.position.x = (siteModel.width * scale/2);
      mesh.position.z = (-siteModel.height * scale/2);
      mesh.name = baseName;
      scene.add(mesh)
      document.body.id = "textureLoaded"
    });
  }

}

// once everything is loaded, we run our Three.js stuff.
function renderBuilding(
  siteModel: SiteModel,
  selectedId: string,
  viewportId: string,
  onShapeSelect: (id: string) => void,
  onRoofError: () => void,
) {
  // create a scene, that will hold all our elements such as objects, cameras and lights.
  var scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xcce0ff );
  scene.fog = new THREE.Fog( 0xcce0ff, 500, 10000 );

  // add models to scene
  const buildings = Object.values(siteModel.buildings);
  let siteModelDepth = 0
  for (const building of buildings) {
    if(building.depth > siteModelDepth) {
      siteModelDepth = building.depth;
    }
    const shape = createShape(
      building.path,
      building.holes,
      building.depth,
      (building.id === selectedId),
      building.id,
      building.color,
      building.roofShape,
      onRoofError,
    );
    scene.add(shape);
  }
  const viewport = document.getElementById(viewportId);
  const width = viewport ? viewport.offsetWidth : 500;
  const height = viewport ? viewport.offsetHeight : 500;
  // create a camera, which defines where we're looking at.
  var camera = new THREE.PerspectiveCamera(30, width / height, 1, 10000);
  // position and point the camera to the center of the scene

  camera.position.x = -200 - siteModelDepth;
  camera.position.y = siteModelDepth + 128;
  camera.position.z = siteModelDepth + 128;

  // LIGHTS
	var hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, 0.87);
  hemiLight.position.set( 0, 20, 0 );
  scene.add( hemiLight );
  const light = directionalLight(camera.position)
  scene.add(light);
  // GROUND
  addGround(scene, siteModel);

  // create a render and set the size
  var renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setClearColor(new THREE.Color(0xEEEEEE));
  renderer.setSize(width, height);
  renderer.shadowMap.enabled = true;

  // add the output of the renderer to the html element
  if (viewport) {
    viewport.innerHTML = "";
    viewport.appendChild(renderer.domElement);
  } else {
    console.error("View port not found");
  }

  const orbit = new OrbitControls(camera, renderer.domElement);
  orbit.target.set(siteModel.width * scale/2, (siteModelDepth/2), -(siteModel.height * scale/2))
  orbit.maxPolarAngle = Math.PI * 0.5;
  orbit.update()

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  function onDblClick(event: MouseEvent) {
    if (!event.target) {
      return;
    }
    const target = event.target as HTMLCanvasElement;
    if(!(target.parentElement && (target.parentElement.id == viewportId))) {
      return;
    }
    event.preventDefault();

    const rect = target.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / width) * 2 - 1;
    mouse.y = (1 - ((event.clientY - rect.top) / height)) * 2 - 1;

    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
      onShapeSelect(intersects[0].object.name);
    }
  }
  document.addEventListener('dblclick', onDblClick, false );

  render();
  function render() {
    orbit.update();
    // render using requestAnimationFrame
    requestAnimationFrame(render);
    renderer.render(scene, camera);
  }

  const controls: Controls = {
    updateSelected: (newModel: BuildingModel, oldModel?: BuildingModel) => {
      const newSelected = scene.getObjectByName(newModel.id);
      if(!newSelected) {
        console.error("tried to select shape that does not exist");
        return;
      }
      scene.remove(newSelected);
      const selected = createShape(
        newModel.path,
        newModel.holes,
        newModel.depth,
        true,
        newModel.id,
        newModel.color,
        newModel.roofShape,
        onRoofError
      )
      scene.add(selected);

      if (oldModel === undefined) {
        return;
      }

      const oldSelected = scene.getObjectByName(oldModel.id);
      if(!oldSelected) {
        console.error("scene and intrenal model out of sync");
        return;
      }
      scene.remove(oldSelected);
      const deSelected = createShape(
        oldModel.path,
        oldModel.holes,
        oldModel.depth,
        false,
        oldModel.id,
        oldModel.color,
        oldModel.roofShape,
        onRoofError
      )
  
      scene.add(deSelected);

    },
    updateBase: (siteModel: SiteModel): void => {
      const base = scene.getObjectByName(baseName);
      if (base) {
        scene.remove(base);
      }
      addGround(scene, siteModel);
    },
    removeModel: (name: string) => {
      const shape = scene.getObjectByName(name);

      if(!(shape)) {
        console.error("can not remove shape that does not exist in scene");
        return;
      }
      scene.remove(shape);
    },
    updateBuilding(model: BuildingModel, selected: string) {
      const shape = scene.getObjectByName(model.id);
      if(!shape) {
        console.error("can not change height on model that does not exist in secne");
        return;
      }
      scene.remove(shape);
      const updatedShape = createShape(
        model.path,
        model.holes,
        model.depth,
        model.id === selected,
        model.id,
        model.color,
        model.roofShape,
        onRoofError
      )
      scene.add(updatedShape);
    },
    getStlOfModel: (name: string) => {
      const shape = scene.getObjectByName(name);

      if(!(shape)) {
        console.error("can not remove shape that does not exist in scene");
        return;
      }
      shape.rotation.x = 0
      const stl = exportSTL.fromMesh(shape, false)
      shape.rotation.x = - Math.PI / 2;
      return stl;
    },
  };
  return controls
}

export function renderRoof(footprint: Point[], viewportId: string) {
  // create a scene, that will hold all our elements such as objects, cameras and lights.
  var scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xcce0ff );
  scene.fog = new THREE.Fog( 0xcce0ff, 500, 10000 );

  // add roof to scene
  const roof = createRoof(footprint, 45, 0);
  var meshMaterial = new THREE.MeshPhongMaterial({
    color: 0x3398CA,
    shininess: 0,
    side: THREE.DoubleSide
  });

  var mesh = new THREE.Mesh(roof, meshMaterial);
  mesh.rotation.x = - Math.PI / 2;
  mesh.castShadow = true;
  scene.add(mesh);
  const viewport = document.getElementById(viewportId);
  const width = viewport ? viewport.offsetWidth : 500;
  const height = viewport ? viewport.offsetHeight : 500;
  // create a camera, which defines where we're looking at.
  var camera = new THREE.PerspectiveCamera(30, width / height, 1, 10000);
  // position and point the camera to the center of the scene

  camera.position.x = -300;
  camera.position.y = 300;
  camera.position.z = 300;

  // LIGHTS
	var hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, 0.87);
  hemiLight.position.set( 0, 400, 0 );
  scene.add( hemiLight );
  const light = directionalLight(camera.position)
  scene.add(light);


  // create a render and set the size
  var renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setClearColor(new THREE.Color(0xEEEEEE));
  renderer.setSize(width, height);
  renderer.shadowMap.enabled = true;

  // add the output of the renderer to the html element
  if (viewport) {
    viewport.innerHTML = "";
    viewport.appendChild(renderer.domElement);
  } else {
    console.error("View port not found");
  }

  const orbit = new OrbitControls(camera, renderer.domElement);
  orbit.target.set(50, 50, 25)

  orbit.maxPolarAngle = Math.PI * 0.5;
  orbit.update()

  render();
  function render() {
    orbit.update();
    // render using requestAnimationFrame
    requestAnimationFrame(render);
    renderer.render(scene, camera);
    // console.log(camera.position)
  }
  return;
}

export default renderBuilding;