import * as React from "react"
import "./loading.css"

const Loading = () => {
      return (
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
          </div>
      );
}

export default Loading
