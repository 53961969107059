import Point from "./skeleton/point";
import * as THREE from 'three';

function pathToPoints(path: THREE.Shape): Point[] {
  const points: Point[] = [];
  path.curves.forEach((curve) => {
    if(curve.type !== "LineCurve") {
      console.error(`${curve.type} is not implamented`)
    }
    points.push(new Point(curve.v1.x, curve.v1.y));
  });
  return points;
}

export default pathToPoints