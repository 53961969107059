'use strict';
import Edge from "./edge";

/**
 * A standalone point geometry with useful accessor, comparison, and
 * modification methods.
 * @example
 * var point = new Point(-77, 38);
 */
class Point {
  x: number;
  y: number;

  constructor(x: number, y: number, z?: number) {
    this.x = x;
    this.y = y;
  }

  /**
   * Clone this point, returning a new point that can be modified
   * without affecting the old one.
   */
  clone = (): Point => new Point(this.x, this.y);

  /**
   * Add this point's x & y coordinates to another point,
   * yielding a new point.
   */
  add = (point: Point): Point => this.clone()._add(point);

  /**
   * Subtract this point's x & y coordinates to from point,
   * yielding a new point.
   */
  sub = (point: Point): Point => this.clone()._sub(point);

  /**
   * Multiply this point's x & y coordinates by point,
   * yielding a new point.
   */
  multByPoint = (point: Point): Point => this.clone()._multByPoint(point);

  /**
   * Divide this point's x & y coordinates by point,
   * yielding a new point.
   */
  divByPoint = (point: Point): Point => this.clone()._divByPoint(point);

  /**
   * Multiply this point's x & y coordinates by a factor,
   * yielding a new point.
   */
  mult = (factor: number): Point => this.clone()._mult(factor);

  /**
   * Divide this point's x & y coordinates by a factor,
   * yielding a new point.
   */
  div = (factor: number): Point => this.clone()._div(factor);

  /**
   * Rotate this point around the 0, 0 origin by an angle a,
   * given in radians
   */
  rotate = (angle: number): Point => this.clone()._rotate(angle);

  /**
   * Rotate this point around p point by an angle a,
   * given in radians
   */
  rotateAround(angle: number, point: Point): Point {
    return this.clone()._rotateAround(angle,point);
  }

  /**
   * Multiply this point by a 4x1 transformation matrix
   */
  matMult(transformationMatrix: number[]): Point {
    return this.clone()._matMult(transformationMatrix);
  }

  /**
   * Calculate this point but as a unit vector from 0, 0, meaning
   * that the distance from the resulting point to the 0, 0
   * coordinate will be equal to 1 and the angle from the resulting
   * point to the 0, 0 coordinate will be the same as before.
   */
  unit = (): Point => this.clone()._unit();

  /**
   * Compute a perpendicular point, where the new y coordinate
   * is the old x coordinate and the new x coordinate is the old y
   * coordinate multiplied by -1
   */
  perp = () => this.clone()._perp();

  /**
   * Return a version of this point with the x & y coordinates
   * rounded to integers.
   */
  round = () => this.clone()._round();

  /**
   * Return the magitude of this point: this is the Euclidean
   * distance from the 0, 0 coordinate to this point's x and y
   * coordinates.
   */
  mag = (): number => Math.sqrt(this.x * this.x + this.y * this.y);

  /**
   * Judge whether this point is equal to another point, returning
   * true or false.
   */
  equals = (other: Point): boolean => this.x === other.x && this.y === other.y;
  

  /**
   * Calculate the distance from this point to another point
   */
  dist = (point: Point): number => Math.sqrt(this.distSqr(point))

  /**
   * Calculate the distance from this point to another point,
   * without the square root step. Useful if you're comparing
   * relative distances.
   */
  distSqr(point: Point): number {
    var dx = point.x - this.x,
      dy = point.y - this.y;
    return dx * dx + dy * dy;
  }

  /**
   * Get the angle from the 0, 0 coordinate to this point, in radians
   * coordinates.
   */
  angle(): number {
    return Math.atan2(this.y, this.x);
  }

  /**
   * Get the angle from this point to another point, in radians
   */
  angleTo(point: Point): number {
    return Math.atan2(this.y - point.y, this.x - point.x);
  }

  /**
   * Get the angle between this point and another point, in radians
   */
  angleWith(point: Point): number {
    return this.angleWithSep(point.x, point.y);
  }

  /**
   * Find the angle of the two vectors, solving the formula for
   * the cross product a x b = |a||b|sin(θ) for θ.
   * @return {Number} the angle in radians
   */
  angleWithSep(x: number, y: number): number {
    return Math.atan2(
      this.x * y - this.y * x,
      this.x * x + this.y * y);
  }

  _matMult(m: number[]): Point {
    var x = m[0] * this.x + m[1] * this.y,
      y = m[2] * this.x + m[3] * this.y;
    this.x = x;
    this.y = y;
    return this;
  }

  _add(p: Point): Point {
    this.x += p.x;
    this.y += p.y;
    return this;
  }

  _sub(p: Point): Point {
    this.x -= p.x;
    this.y -= p.y;
    return this;
  }

  _mult(k: number): Point {
    this.x *= k;
    this.y *= k;
    return this;
  }

  _div(k: number): Point {
    this.x /= k;
    this.y /= k;
    return this;
  }

  _multByPoint(p: Point): Point {
    this.x *= p.x;
    this.y *= p.y;
    return this;
  }

  _divByPoint(p: Point): Point {
    this.x /= p.x;
    this.y /= p.y;
    return this;
  }

  _unit() {
    this._div(this.mag());
    return this;
  }

  _perp() {
    var y = this.y;
    this.y = this.x;
    this.x = -y;
    return this;
  }

  _rotate(angle: number): Point {
    var cos = Math.cos(angle),
      sin = Math.sin(angle),
      x = cos * this.x - sin * this.y,
      y = sin * this.x + cos * this.y;
    this.x = x;
    this.y = y;
    return this;
  }

  _rotateAround(angle: number, p: Point): Point {
    var cos = Math.cos(angle),
      sin = Math.sin(angle),
      x = p.x + cos * (this.x - p.x) - sin * (this.y - p.y),
      y = p.y + sin * (this.x - p.x) + cos * (this.y - p.y);
    this.x = x;
    this.y = y;
    return this;
  }

  _round(): Point {
    this.x = Math.round(this.x);
    this.y = Math.round(this.y);
    return this;
  }
}

export default Point

export interface Node extends Point {
  distanceToEdge?: number;
  next?: Node;
  prev?: Node;
  label?: string;
  e1?: Edge;
  e2?: Edge;
  skelNext?: Node;
  skelPrev?: Node;
  used?: boolean;
  third?: Node;
  splitVersion?: number;
  splitEvent?: boolean;
  bisector?: Node;
  isReflex?: boolean;
  isIntersection?: boolean
}

export interface LinkedNode extends Point {
  next: Node;
  prev: Node;
  label: string;
}
