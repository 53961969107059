import * as THREE from 'three';
import skeleton from "../skeleton/skeleton";
import Point from "../skeleton/point";

interface Point3D {
  x: number;
  y: number;
  z: number;
}

function getHeight(angle: number, distance?: number): number {
  if (!distance) {
    return 0;
  }
  return Math.tan(angle * Math.PI/180) * distance
}

function triangulate(polygon: Point3D[]): number[] {
  const indices = THREE.ShapeUtils.triangulateShape(polygon, [])
  const vertices: number[] = [];
  indices.forEach((index) => {
    const points = [
      polygon[index[0]],
      polygon[index[1]],
      polygon[index[2]],
    ]
    points.forEach(point => {
      vertices.push(point.x, point.y, point.z)
    })
  });
  return vertices
}

function footprintVericites(footprint: Point[], groundLevel: number): number[] {
  const footprint3D: Point3D[] = footprint.map(point => {
    (point as any as Point3D).z = groundLevel;
    return point;
  }) as any as Point3D[];
  return triangulate(footprint3D);
}

function wallVericites(footprint: Point[], groundLevel: number, depth: number): number[] {
  let vertices: number[] = [];
  for (let i = 0; i < footprint.length; i++) {
    const prevIndex = i === 0 ? footprint.length - 1 : i - 1;
    const previous = footprint[prevIndex];
    const current = footprint[i];
    vertices = vertices.concat(
      [
        current.x, current.y, groundLevel,
        previous.x, previous.y, depth,
        current.x, current.y, depth,

        current.x, current.y, groundLevel,
        previous.x, previous.y, groundLevel,
        previous.x, previous.y, depth,
      ]
    )
  }
  return vertices;
}

function roofVericites(roof: Polygon[], angle: number, offset: number): number[] {
  // calc 3d points
  const roof3D = roof.map((polygon) => {
    const points: Point3D[] = []
    polygon.forEach(point => {
      const exist = points.find(function(p) {
        return (p[0] === point.x && p[1] === point.y);
      });
      if (exist) {
        return;
      }

      const depth = getHeight(angle, point.distanceToEdge);
      point = new Point(point.x, point.y);
      point.z = depth + offset
      points.push(point)
    })
    return points
  });

  // triangulate 3d points
  const verticies = roof3D.map(polygon => {
    return triangulate(polygon)
  })
  return verticies.flat();
}

function bbox(footprint: Point[]) {
  let minX = footprint[0].x
  let minY = footprint[0].y
  let maxX = footprint[0].x
  let maxY = footprint[0].y
  footprint.map(point => {
    if (point.x < minX) {
      minX = point.x
    }
    if (point.x > maxX) {
      maxX = point.x
    }
    if (point.y < minY) {
      minY = point.y
    }
    if (point.y > maxY) {
      maxY = point.y
    }
  })
  return {minX, minY, maxX, maxY}
}

function isRoofValid(footprint: Point[], roof: Polygon[]): boolean {
  const box = bbox(footprint);
  let valid = true;
  roof.forEach((polygon) => {
    polygon.forEach(point => {
      if(
        point.x < box.minX ||
        point.y < box.minY ||
        point.x > box.maxX ||
        point.y > box.maxY
      ) {
        valid = false;
      }
    })
  })
  return valid
}

function createRoof(footprint: Point[], angle: number, offset: number, onRoofError: () => void): THREE.BufferGeometry {
  const geometry = new THREE.BufferGeometry();
  const groundLevel = 0
  let vertices = footprintVericites(footprint, groundLevel)
                 .concat(wallVericites(footprint, groundLevel, offset));

  let roofAdded = false
  try {
    const roof = skeleton(footprint, 4000)
    if (isRoofValid(footprint, roof)) {
      vertices = vertices.concat(roofVericites(roof, angle, offset));
      roofAdded = true
    }
  } catch(e) {
    console.error(e)
  }
  if(!roofAdded) {
    // HACK: reverseing the footprint may be necessary for skeleton to work
    try {
      const roof = skeleton(footprint.reverse(), 4000)
      if (isRoofValid(footprint, roof)) {
        vertices = vertices.concat(roofVericites(roof, angle, offset));
        roofAdded = true
      }
    } catch(e) {
      console.error(e)
    }
  }

  if(!roofAdded) {
    onRoofError();
    vertices = vertices.concat(footprintVericites(footprint, offset));
  }

  geometry.addAttribute(
    'position',
    new THREE.BufferAttribute( new Float32Array(vertices), 3 )
  );
  geometry.computeVertexNormals();

  return geometry;
}

export default createRoof