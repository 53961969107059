import * as React from "react";

import App from "../components/app/app";
import Layout from "../components/layout";
import SEO from "../components/seo";

class Create extends React.Component<{}, {}> {

  constructor(props: {}) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout noFooter={true}>
        <SEO title="Create"/>
        <App/>
      </Layout>
    )
  }
}

export default Create
