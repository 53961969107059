import * as React from "react"
import Alert from "../ui/alert";
import Radio from "../ui/radio";
import ColorPicker from "../ui/colorPicker";
import RoofSelect from "../ui/roofSelect";

interface Props {
  setSiteModel: (models: SiteModel) => void;
  siteModel?: SiteModel;
  controls?: Controls;
  posterPrice: number;
  selectedId: string | undefined;
}

interface State {
  alert?: Alert;
  defaultColor: Color;
  defaultRoofShape: RoofShape;
}

class BuildingEditor extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      defaultColor: 0xB2756C,
      defaultRoofShape: "flat"
    }
  }

  onScaleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    if(!siteModel) {
      console.error("site models not found for scale change")
      return;
    }
    siteModel.scale = parseInt(e.target.value) as Scale;
    this.props.setSiteModel(siteModel);
  }

  onBaseChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    if(!siteModel) {
      console.error("site models not found for base change")
      return;
    }
    siteModel.base = e.target.value as Base;
    this.props.setSiteModel(siteModel);
    if(!this.props.controls) {
      console.error("tried to update base with out three js controls")
      return;
    }
    this.props.controls.updateBase(siteModel);
  }

  onMaterialChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    if(!siteModel) {
      console.error("site models not found for base change")
      return;
    }
    siteModel.material = e.target.value as Material;
    if (siteModel.material === "STL") {
      siteModel.base = "none"
    } else {
      siteModel.base = "poster"
    }
    this.props.setSiteModel(siteModel);
  }

  modelSize(millimeters: number): number {
    if(!this.props.siteModel) {
      console.error("can not calculate site model size when site model does not exist")
      return 0;
    }
    const meters = millimeters * 1000;
    const inches = meters * 39.37008;
    const size = inches / this.props.siteModel.scale;
    return Number((size).toFixed(1));
  }

  onColorChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models not found for change default color")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    const color = parseInt(e.target.value) as Color;

    Object.values(siteModel.buildings).forEach((building) => {
      building.color = color;
      controls.updateBuilding(building, id);
    });
    this.props.setSiteModel(siteModel);

    this.setState({defaultColor: color});
  }

  onRoofChange(value: {value: string; label: string}) {
    const siteModel: SiteModel = Object.assign({}, this.props.siteModel);
    const id = this.props.selectedId;
    if(!(id && siteModel)) {
      console.error("models not found for change default color")
      return;
    }
    const controls = this.props.controls;
    if (!controls) {
      console.error("controls not found and a model was selected")
      return;
    }
    const roofShape = value.value as RoofShape;

    Object.values(siteModel.buildings).forEach((building) => {
      building.roofShape = roofShape;
      controls.updateBuilding(building, id);
    });
    this.props.setSiteModel(siteModel);

    this.setState({defaultRoofShape: roofShape});
  }

  render() {
    const siteModel = this.props.siteModel
    if (!siteModel) {
      return null;
    }
    return (
      <>
        <Alert alert={this.state.alert}/>
        <div className="form-group">
          <label htmlFor="material">Material</label>
          <Radio
            onChange={this.onMaterialChange.bind(this)}
            value={siteModel.material}
            options={[
              {value: "PLA", label: "Plastic (PLA)"},
              {value: "STL", label: "Download (.stl)"},
            ]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="baseType">Base Type</label>
          <br/>
          <Radio
            onChange={this.onBaseChange.bind(this)}
            value={siteModel.base}
            options={[
              {value: "poster", label: `Poster (+${this.props.posterPrice})`},
              {value: "none", label: "None"},
            ]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="modelScale">Scale</label>
          <select
            className="form-control custom-select"
            value={siteModel.scale}
            id="modelScale"
            onChange={this.onScaleChange.bind(this)}
            aria-describedby="modelSize"
          >
            <option value={100}>1:100 (1/8"=1')</option>
            <option value={160}>1:160 (3/4"=10')</option>
            <option value={200}>1:200 (1/16"=1')</option>
            <option value={240}>1:240</option>
            <option value={384}>1:384 (1/32"=1')</option>
            <option value={500}>1:500</option>
            <option value={1200}>1:1200</option>
          </select>
          <small id="modelSize" className="form-text text-muted">
            Model size: {this.modelSize(siteModel.width)}" x {this.modelSize(siteModel.height)}"
          </small>
        </div>
        <ColorPicker
          label="Default Color"
          value={this.state.defaultColor}
          onChange={this.onColorChange.bind(this)}
        />
        <RoofSelect
          label="Default Roof Shape"
          value={this.state.defaultRoofShape}
          onChange={this.onRoofChange.bind(this)}
        />
      </>
    );
  }
}

export default BuildingEditor
