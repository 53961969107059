import * as React from "react"

interface Props {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: Color;
  label: string
}

const ColorPicker = ({onChange, value, label}: Props) => (
  <div className="form-group">
    <label htmlFor="roofShapeSelect">{label}</label>
    <select
      className="form-control custom-select"
      value={value}
      onChange={onChange}
    >
      <option value={0xB2756C}>Chavant Brown</option>
      <option value={0xFAFAFA}>White</option>
      <option value={0xA9A9A9}>Gray</option>
      <option value={0x3B3B3B}>Black</option>
    </select>
  </div>
)

export default ColorPicker
